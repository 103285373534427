import { getVersion } from "prosemirror-collab"

import { getCitationsState } from "@/store/selectors/citationsSelectors"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import { apiSlice } from "@/store/slices/api"
import { createAppThunk } from "@/store/store"

export const citationDropped = createAppThunk(
  "documents/citationDroppedThunk",
  async (_: void, { getState, dispatch }) => {
    const citationSource = getCitationsState(getState())
    if (!citationSource) return

    const editorState = getRevisionEditorState(
      getState(),
      citationSource.revisionId
    )
    if (!editorState) return

    const version = getVersion(editorState)

    const highlight = await dispatch(
      apiSlice.endpoints.createHighlight.initiate({
        chapterId: citationSource.documentId,
        revisionId: citationSource.revisionId,
        version,
        highlight: {
          revisionId: citationSource.revisionId,
          from: citationSource.from,
          to: citationSource.to,
          color: "yellow",
        },
      })
    ).unwrap()
    return highlight.id
  }
)
