import type { Step } from "prosemirror-transform"

import type { JSONContent } from "./utils"

import type { UUID } from "@/store/UUID"

export type ResponseMeta = {
  chapter_id: UUID
  highlight_id: UUID
  revision_id?: UUID
}

/**
 * CHAPTERS
 */

export type ChapterResponse = {
  id: UUID
  title: string
  description: string
  permissions: {
    is_editable: boolean
  }
  published_revision_id: UUID
  draft_revision_id: UUID
  script_title: string
  script_url: string
}

export type Chapter = {
  id: UUID
  title?: string
  description?: string
  publishedRevisionId?: UUID | null
  draftRevisionId?: UUID
  scriptTitle?: string
  scriptUrl?: string
  permissions?: {
    canEdit: boolean
  }
}

export type ChapterSnapshotResponse = {
  id: UUID
  is_initial: boolean
  version: number
  short_id: string
  content: JSONContent
}

export type ChapterSnapshot = {
  id: UUID
  isInitial?: boolean
  version?: number
  shortId?: string
  content?: JSONContent
}

export type RevisionList = {
  id: UUID
  name: string
  status: RevisionStatus
}[]

export enum RevisionStatus {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  PROCESSING = "processing",
  READY = "ready",
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export type ChapterRevisionResponse = {
  id: UUID
  name: string
  status: RevisionStatus
  snapshot: ChapterSnapshotResponse
}

export type StepsResponse = {
  id: UUID
  version: number
  author_id: UUID
  client_id: UUID
  value: Record<string, unknown>
  created_at: string
}[]

export type Steps = {
  id: UUID
  version: number
  author_id: UUID
  client_id: UUID
  value: Step
  created_at: string
}[]

export type DocumentRevision = {
  id: UUID
  documentId: UUID
  name?: string
  status?: RevisionStatus
  snapshot?: ChapterSnapshot
}

/**
 * EDITOR
 */

export enum VideoPlatform {
  YOUTUBE = "youtube",
  VIMEO = "vimeo",
}

export type VideoSearchResultResponse = {
  items: {
    name: string
    time_code: string | null
    platform: VideoPlatform
    href: string
    snippet: {
      title: string
      channelTitle: string
      thumbnail: string
    }
    video_id: string
  }[]
  success: number
  has_error: number
  errors: string[]
}
export type VideoSearchResult = {
  success: number
  items: VideoSearchResultItem[]
  has_error: number
  errors: string[]
}

export type VideoSearchResultItem = {
  name: string
  timeCode: string | null
  href: string
  snippet: {
    title: string
    channelTitle: string
    thumbnail: string
  }
  videoId: string
  platform: VideoPlatform
}

export type UploadFileResponse = {
  success: number
  file: {
    url: string
    mediaObjectId: UUID
  }
}

/**
 * FLASHCARDS
 */

export type KnowledgeResponse = {
  queue: 0 | 1 | 2
  ease_factor: number
  due_at: string
}

export type Knowledge = {
  queue: 0 | 1 | 2
  easeFactor: number
  dueAt: string
}

export type FlashcardResponse = {
  id: UUID
  published_revision_id: UUID
  draft_revision_id: UUID
  deck_id: UUID
  deck_title: string
  deck_url: string
  knowledge?: KnowledgeResponse
  highlight?: {
    id: UUID
    document_id: UUID
  }
}

export type Flashcard = {
  id: UUID
  publishedRevisionId?: UUID | null
  draftRevisionId?: UUID
  deckId: UUID
  deckTitle: string
  deckUrl: string
  knowledge?: Knowledge
  highlight?: {
    id: UUID
    documentId: UUID
  }
}

export type FlashcardDeckResponse = {
  id: UUID
  title: string
  owner: string
  description?: string
  flashcards?: FlashcardResponse[]
  slug: string
  can_user_edit: boolean
  has_user_saved_deck: boolean
  is_user_owner: boolean
}

export type FlashcardDeck = {
  id: UUID
  title: string
  owner: string
  canUserEdit: boolean
  description?: string
  flashcards: Flashcard[]
  slug: string
  hasUserSavedDeck: boolean
  isUserOwner: boolean
}

export enum FlashcardReportLevel {
  AGAIN = "again",
  HARD = "hard",
  GOOD = "good",
  EASY = "easy",
}

export type StudySessionResponse = {
  uuid: UUID
  next_flashcard: {
    id: UUID
    draft_revision_id: UUID
    annotation?: UUID
  }
  next_flashcard_intervals: {
    [FlashcardReportLevel.AGAIN]: number
    [FlashcardReportLevel.HARD]: number
    [FlashcardReportLevel.GOOD]: number
    [FlashcardReportLevel.EASY]: number
  }
  history: Array<{
    reported_ease: FlashcardReportLevel
    has_follow_up: boolean
  }>
  deck: UUID
  completed_at?: string
}

export type StudySession = {
  uuid: UUID
  nextFlashcard: {
    id: UUID
    draftRevisionId: UUID
    annotation?: UUID
  }
  nextFlashcardIntervals: {
    [FlashcardReportLevel.AGAIN]: number
    [FlashcardReportLevel.HARD]: number
    [FlashcardReportLevel.GOOD]: number
    [FlashcardReportLevel.EASY]: number
  }
  history: Array<{
    reportedEase: FlashcardReportLevel
    hasFollowUp: boolean
  }>
  deck: UUID
  completedAt?: string
}

/**
 * NOTEBOOKS
 */

export type NotebookResponse = {
  id: UUID
  title: string
  owner: string
  description?: string
  notes?: NoteResponse[]
  slug: string
  can_user_edit: boolean
  has_user_saved_notebook: boolean
  is_user_owner: boolean
}

export type Notebook = {
  id: UUID
  title: string
  owner: string
  canUserEdit: boolean
  description?: string
  notes: Note[]
  slug: string
  hasUserSavedNotebook: boolean
  isUserOwner: boolean
}

export type NoteResponse = {
  id: UUID
  published_revision_id: UUID
  draft_revision_id: UUID
  notebook_id: UUID
  notebook_title: string
  notebook_url: string
  title: string
  description: string
  url: string
  is_last_used: boolean
}

export type Note = {
  id: UUID
  publishedRevisionId?: UUID | null
  draftRevisionId?: UUID
  notebookId: UUID
  notebookTitle: string
  notebookUrl: string
  title: string
  description: string
  url: string
  isLastUsed: boolean
}

/**
 * DOCUMENT CONTAINER PERMISSIONS
 */

export enum PermissionEntityType {
  USER = "user",
  INSTITUTION = "institution",
  USERGROUP = "usergroup",
  GROUP = "group",
}

export enum PermissionType {
  VIEW = "view",
  CHANGE = "change",
}

export type PermissionEntity = {
  resourceId: UUID
  entity: number
  entityType: PermissionEntityType
  permission: PermissionType
}

export type PermissionEntitySearchResult = {
  users: PermissionSearchEntity[]
  usergroups: PermissionSearchEntity[]
  institutions: PermissionSearchEntity[]
}

export type PermissionSearchEntity = {
  id: number
  name: string
  entityType: PermissionEntityType
}

export type DocumentContainerPermissions = {
  canPublishGlobally: boolean
  isOpenAccess: boolean
  openAccessGroupId: number
  isPlatformWide: boolean
  platformWideGroupId: number
  users: UserPermissionRecord[]
  institutions: InstitutionPermissionRecord[]
  usergroups: UsergroupPermissionRecord[]
}

export type UserPermissionRecord = {
  entity: DocumentContainerPermissionsUser
  permissions: PermissionType
  entityType: PermissionEntityType.USER
}

export type InstitutionPermissionRecord = {
  entity: DocumentContainerPermissionsInstitution
  permissions: PermissionType
  entityType: PermissionEntityType.INSTITUTION
}

export type UsergroupPermissionRecord = {
  entity: DocumentContainerPermissionsUsergroup
  permissions: PermissionType
  entityType: PermissionEntityType.USERGROUP
}

export type DocumentContainerPermissionRecord =
  | UserPermissionRecord
  | InstitutionPermissionRecord
  | UsergroupPermissionRecord

export type DocumentContainerPermissionsUser = {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  initials: string
  isInvited: boolean
  isEditor: boolean
  isOwner: boolean
  profilePictureUrl?: string
  invitation?: Invitation
}

export type DocumentContainerPermissionsInstitution = {
  id: number
  name: string
}

export type DocumentContainerPermissionsUsergroup = {
  id: number
  name: string
}

export type FlashcardText = { flashcardFront: string; flashcardBack: string }

/**
 * DOCUMENT IMPORT FLOW
 */

export enum ImportFlowStatus {
  INITIATED = "initiated",
  PARSING_FILES = "parsing_files",
  AWAITING_SEGMENTATION = "awaiting_segmentation",
  PROCESSING_CHAPTERS = "processing_chapters",
  COMPLETED = "completed",
  FAILED = "failed",
  CANCELLED = "cancelled",
}

export type ImportFlowDetailResponse = {
  id: UUID
  script_id: UUID
  script_url: string
  status: ImportFlowStatus
  started_at: string
  finished_at: string
}

export type ImportFlowDetail = {
  id: UUID
  scriptId: UUID
  scriptUrl: string
  status: ImportFlowStatus
  startedAt: string
  finishedAt: string
}

export type ImportDocumentDetailResponse = {
  id: UUID
  original_filename: string
  char_count: number
  token_count: number
  split_points: ImportSplitPointResponse[]
}

export type ImportDocumentDetail = {
  id: UUID
  originalFilename: string
  charCount: number
  tokenCount: number
  splitPoints: ImportSplitPoint[]
}

export type ImportSplitPointResponse = {
  id: UUID
  heading_level: number
  heading_title: string
  char_index: number
  token_index: number
  char_count: number
  token_count: number
  section_char_count: number
  section_token_count: number
}

export type ImportSplitPoint = {
  id: UUID
  headingLevel: number
  headingTitle: string
  charIndex: number
  tokenIndex: number
  charCount: number
  tokenCount: number
  sectionCharCount: number
  sectionTokenCount: number
}

export type ImportDocumentSplitDetail = {
  id: UUID
  documentId: UUID
  splitName: string
  startSplitPointId: UUID | null
  uptoSplitPointId: UUID | null
  omitPrimaryHeading?: boolean
  headingLevelAdjustment?: number
  stripHeadingNumbering?: boolean
}

/**
 * HIGHLIGHTS
 */

export type HighlightResponse = {
  id: UUID
  start: number
  end: number
  color: string
  position_status: string
  revision_id: UUID
  thread_id: UUID
  flashcard_id: UUID
  step_version: number
  created_at: string
  created_by: number
}
export type Highlight = {
  id: UUID
  from: number
  to: number
  color: string
  positionStatus: string
  revisionId: UUID
  threadId: UUID | null
  flashcardId: UUID | null
  stepVersion: number
  createdAt: string
  createdBy: number
  top?: number
  right?: number
}

export type UpdateHighlightRequest = {
  color: string
}

/**
 * RESOURCES
 */

export type CourtDecisionResponse = {
  uuid: UUID
  docket_number: string
  ecli: string
  title: string
  date: string
  decision_type: string
  relevant_sections: string[]
  previous_instances: string[]
  reasoning: string
  operative: string
  facts: string
  guiding_principles: string
  court_name: string
}
export type CourtDecision = {
  url: string
  id?: string
  isLoading?: boolean
  docketNumber?: string
  ecli?: string
  title?: string
  date?: string
  decisionType?: string
  relevantSections?: string[]
  previousInstances?: string[]
  reasoning?: string
  operative?: string
  facts?: string
  guidingPrinciples?: string
  courtName?: string
}

export type StatuteSectionResponse = {
  selected_section_uuid: UUID
  sections: {
    uuid: UUID
    body: string
    slug: string
    statute_abbreviation: string
    title: string
    referer: string
  }[]
}
export type StatuteSection = {
  url: string
  uuid?: UUID
  /** HTML string */
  body?: string
  /** e.g. "2378" */
  slug?: string
  /** e.g. "BGB" */
  statuteAbbreviation?: string
  /** "Nachlassverbindlichkeiten" */
  title?: string
  /** e.g. "§ 2378" */
  referer?: string
}

export type ItemResourceSearchResult = {
  uuid: UUID
  title: string
  url: string
  fullName: string
}

export type ReferenceResourceSearchResultResponse = {
  uuid: UUID
  court_name: string
  date: string
  file_reference: string
  url: string
  journal_abbreviation: string
  volume: string
  page: string
  title: string
  full_name: string
}

export interface ReferenceResourceSearchResult
  extends ItemResourceSearchResult {
  courtName: string
  date: string
  fileReference: string
  journalAbbreviation: string
  volume: string
  page: string
}

export type SectionResourceSearchResultResponse = {
  uuid: UUID
  referer: string
  title: string
  body: string
  slug: string
  statute_abbreviation: string
  url: string
  full_name: string
}

export interface SectionResourceSearchResult extends ItemResourceSearchResult {
  referer: string
  body: string
  slug: string
  statuteAbbreviation: string
}

export type CourtDecisionResourceSearchResultResponse = {
  uuid: UUID
  docket_number: string
  ecli: string
  title: string
  date: string
  decision_type: string
  court_name: string
  url: string
  full_name: string
}

export interface CourtDecisionResourceSearchResult
  extends ItemResourceSearchResult {
  docketNumber: string
  ecli: string
  date: string
  decisionType: string
  courtName: string
}

export type ResourceSearchResultsResponse = {
  references: ReferenceResourceSearchResultResponse[]
  sections: SectionResourceSearchResultResponse[]
  court_decisions: CourtDecisionResourceSearchResultResponse[]
}

export type ResourceSearchResults = {
  references: ReferenceResourceSearchResult[]
  sections: SectionResourceSearchResult[]
  courtDecisions: CourtDecisionResourceSearchResult[]
}

/**
 * MAGIC
 */
export type MagicQuizResponse = MagicQuiz
export type MagicQuiz = {
  question: string
  answers: { correct: boolean; text: string }[]
  explanation?: string
}

/**
 * MESSAGES AND THREADS
 */

export type MessageResponse = {
  id: UUID
  thread_id: UUID
  content: JSONContent
  created_by: UUID
  created_by_name: string
  mentioned_users: UUID[]
  created_at: string
  updated_at: string
}
export type Message = {
  id: UUID
  content: JSONContent
  threadId?: UUID
  createdBy?: UUID
  createdByName?: string
  mentionedUsers?: UUID[]
  createdAt?: string
  updatedAt?: string
}

/**
 * USERS
 */

export type UserSearchResultResponse = {
  id: UUID
  first_name: string
  last_name: string
  initials: string
  username: string
  profile_picture: string
  institutions: string[]
}

export type UserSearchResult = {
  id: UUID
  firstName: string
  lastName: string
  initials: string
  username: string
  profilePictureUrl: string
  institutions: string[]
}

/**
 * INSTITUTIONS
 */

export type Invitation = {
  id: UUID
  created: string
}

export type InstitutionInfoResponse = {
  id: number
  name: string
  country: string
  slug: string
  profile_picture: string
  about: string
  website: string
  domains: InstitutionDomain[]
}

export type InstitutionInfo = {
  id: number
  name: string
  country: string
  slug: string
  profilePictureUrl: string
  about: string
  website: string
  domains: InstitutionDomain[]
}

export type InstitutionDomain = {
  uuid: UUID
  name: string
  institution: number
}

export type InstitutionUsersResponse = {
  count: number
  results: InstitutionUserResponse[]
}

export type InstitutionUsers = {
  count: number
  results: InstitutionUser[]
}

export type InstitutionUserResponse = {
  id: number
  first_name: string
  last_name: string
  email: string
  initials: string
  username: string
  profile_picture?: string
  is_invited: boolean
  is_admin: boolean
  invitation?: Invitation
}

export type InstitutionUser = {
  id: number
  firstName: string
  lastName: string
  email: string
  initials: string
  username: string
  profilePictureUrl?: string
  isAdmin: boolean
  isInvited: boolean
  invitation?: Invitation
}

export type InstitutionUserTypeaheadResponse = {
  id: number
  first_name: string
  last_name: string
  initials: string
  username: string
  profile_picture?: string
  institutions: string
  already_in_institution: boolean
  email: string
}

export type InstitutionUserTypeahead = {
  type: "user"
  id: number
  firstName: string
  lastName: string
  initials: string
  username: string
  profilePictureUrl?: string
  institutions: string
  alreadyInInstitution: boolean
  email: string
}

export interface AddUser {
  type: "user"
  id: number
  label: string
  entity: {
    id: number
    firstName: string
    lastName: string
    username: string
    email: string
  }
}

export interface AddInvite {
  type: "invite"
  id: UUID
  label: string
  entity: {
    email: string
  }
}

interface AddPending {
  type: "pending"
  id: string
  label: string
  entity: null
}

interface AddFailed {
  type: "failed"
  id: number
  label: string
  entity: null
}

export type AddEntity = AddUser | AddInvite | AddPending | AddFailed

export type VerifyUsersInInstitutionResponse = {
  [email: string]: {
    is_user: boolean
    is_in_institution?: boolean
    user?: {
      id: number
      first_name: string
      last_name: string
      initials: string
      username: string
      email: string
    }
  }
}

export type VerifyUsersInInstitution = {
  [email: string]: {
    isUser: boolean
    isInInstitution?: boolean
    user?: {
      id: number
      firstName: string
      lastName: string
      initials: string
      username: string
      email: string
    }
  }
}

/*
 * USER SETTINGS
 */

export type UserSettingsResponse = {
  is_author: boolean
  id: number
  title_prefix: string
  title_suffix: string
  first_name: string
  last_name: string
  bio: string
  website: string
  page_availability: string
  semester: number
  profile_picture: string
  initials: string
  institutions: UserSettingsInstitutionResponse[]
  username: string
}

export type UserSettingsInstitutionResponse = {
  id: number
  name: string
  country: string
  slug: string
  profile_picture: string
  about: string
  website: string
  domains: UserSettingsInstitutionDomainsResponse[]
  initials: string
  sso_client_id: string
  is_sso_active: boolean
}

export type UserSettingsInstitutionDomainsResponse = {
  uuid: UUID
  name: string
  institution: number
}

export type UserSettings = {
  isAuthor: boolean
  id: number
  titlePrefix: string
  titleSuffix: string
  firstName: string
  lastName: string
  bio: string
  website: string
  pageAvailability: string
  semester: number
  profilePictureUrl: string
  initials: string
  institutions: UserSettingsInstitution[]
  username: string
}

export type UserSettingsInstitution = {
  id: number
  name: string
  country: string
  slug: string
  profilePictureUrl: string
  about: string
  website: string
  domains: UserSettingsInstitutionDomains[]
  initials: string
  ssoClientId: string
  isSsoActive: boolean
}

export type UserSettingsInstitutionDomains = {
  uuid: UUID
  name: string
  institution: number
}

export type UserSettingsEmail = {
  id: UUID
  user: number
  email: string
  verified: boolean
  primary: boolean
}

export type GetUserInfoResponse = {
  id: UUID
  title_prefix: string
  title_suffix: string
  first_name: string
  last_name: string
  bio: string
  website: string
  page_availability: string
  semester: number
  profile_picture: string
  initials: string
  institutions: InstitutionInfo[]
  username: string
}

export type EmailAddress = {
  id: number
  user: UUID
  email: string
  verified: boolean
}

export type UserProfileData = {
  id: UUID
  titlePrefix: string
  isAuthor?: boolean
  titleSuffix: string
  firstName: string
  lastName: string
  bio: string
  website: string
  pageAvailability: string
  semester: number
  profilePicture: string
  initials: string
  institutions: InstitutionInfo[]
  userName: string
}

export type PaymentMethodResponse = {
  id: string
  type: string
  card: {
    brand: string
    last4: string
  }
  sepa_debit: {
    last4: string
  }
}

export type PaymentMethod = {
  id: string
  type: string
  card: {
    brand: string
    last4: string
  }
  sepaDebit: {
    last4: string
  }
}

export type SubscriptionPlan = {
  id: string
  nickname: string
  amount: number
  currency: string
  interval: string
  interval_count: number
  product_name: string
}

export type SubscriptionsResponse = {
  personal_subscriptions: SubscriptionResponse[]
  institution_subscriptions: string[]
}

export type Subscriptions = {
  personalSubscriptions: Subscription[]
  institutionSubscriptions: string[]
}

export type SubscriptionResponse = {
  id: string
  status: string
  plan: SubscriptionPlan
  cancel_at_period_end: boolean
  canceled_at: number
  start_date: number
  current_period_start: number
  current_period_end: number
  ended_at: number
  trial_end: number
  default_payment_method: PaymentMethodResponse
}

export type Subscription = {
  id: string
  status: string
  plan: SubscriptionPlan
  cancelAtPeriodEnd: boolean
  canceledAt: number
  startDate: number
  currentPeriodStart: number
  currentPeriodEnd: number
  endedAt: number
  trialEnd: number
  defaultPaymentMethod?: PaymentMethod
}

export type ReferenceLink = {
  start: number
  end: number
  text: string
  resource: {
    href: string
    title: string
  }
}

export type ReferenceLinksResponse = {
  links: ReferenceLink[]
}

export type LinkableResourcesResponse = {
  statute_abbrs: string[]
  court_types: string[]
  journal_abbrs: string[]
}
