import { type PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { apiSlice } from "./api"

import type { UUID } from "@/store/UUID"

type ViewerState = {
  marker: {
    enabled: boolean
    color: "yellow" | "green" | "pink" | "blue" | "violet" | null
    current: {
      anchor: number
      head: number
    } | null
  }
  activeHighlightId: UUID | null
  stepThrough: Record<
    UUID,
    {
      enabled: boolean
      step: number
      length: number
    }
  >
  storedScroll: number | null
}

const initialState: ViewerState = {
  marker: { enabled: false, color: null, current: null },
  activeHighlightId: null,
  stepThrough: {},
  storedScroll: null,
}

export const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    highlightActivated(state, action: PayloadAction<{ highlightId: UUID }>) {
      state.activeHighlightId = action.payload.highlightId
    },
    highlightDeactivated(state) {
      state.activeHighlightId = null
    },
    markerModeEnabled(
      state,
      action: PayloadAction<{
        color: "yellow" | "green" | "pink" | "blue" | "violet"
      }>
    ) {
      state.marker.enabled = true
      state.marker.color = action.payload.color
    },
    markerModeDisabled(state) {
      state.marker.enabled = false
      state.marker.color = null
    },
    currentMarkStarted(state, action: PayloadAction<{ anchor: number }>) {
      const { anchor } = action.payload
      state.marker.current = { anchor, head: anchor }
    },
    currentMarkUpdated(state, action: PayloadAction<{ head: number }>) {
      const { head } = action.payload

      if (!state.marker.current) return
      state.marker.current.head = head
    },
    currentMarkCancelled(state) {
      state.marker.current = null
    },
    stepThroughEnabled(
      state,
      action: PayloadAction<{
        revisionId: UUID
        length: number
        scroll: number | undefined
      }>
    ) {
      const { revisionId, length, scroll } = action.payload

      state.stepThrough[revisionId] = {
        enabled: true,
        step: 0,
        length,
      }
      if (scroll !== undefined) state.storedScroll = scroll
    },
    stepThroughDisabled(
      state,
      action: PayloadAction<{ revisionId: UUID; scroll: number | undefined }>
    ) {
      const { revisionId, scroll } = action.payload

      const stepThroughState = state.stepThrough[revisionId]
      if (!stepThroughState) return

      stepThroughState.enabled = false
      if (scroll !== undefined) state.storedScroll = scroll
    },
    prevStepClicked(
      state,
      action: PayloadAction<{ revisionId: UUID; scroll: number | undefined }>
    ) {
      const { revisionId, scroll } = action.payload

      const stepThroughState = state.stepThrough[revisionId]
      if (!stepThroughState) return

      if (stepThroughState.step === 0) return

      stepThroughState.step -= 1
      if (scroll !== undefined) state.storedScroll = scroll
    },
    nextStepClicked(
      state,
      action: PayloadAction<{ revisionId: UUID; scroll: number | undefined }>
    ) {
      const { revisionId, scroll } = action.payload

      const stepThroughState = state.stepThrough[revisionId]
      if (!stepThroughState) return

      if (stepThroughState.step === stepThroughState.length - 1) return

      stepThroughState.step += 1
      if (scroll !== undefined) state.storedScroll = scroll
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        apiSlice.endpoints.createHighlight.matchFulfilled,
        apiSlice.endpoints.createHighlight.matchRejected
      ),
      (state) => {
        state.marker.current = null
      }
    )
  },
})
