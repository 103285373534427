import type { Node } from "prosemirror-model"
import { Plugin, PluginKey } from "prosemirror-state"

import { findLastBlock } from "@/utils/prosemirror"
import { sendErrorToSentry } from "@/utils/sentry"

/**
 * This plugin automatically adds / removes margin numbers at the end of the document.
 * If the last block has content and no margin number, it will add a new margin number.
 * If the last block has no content and a margin number, it will remove the margin number.
 */

export function marginNumbers() {
  return new Plugin({
    key: new PluginKey("marginNumbers"),

    appendTransaction(_, oldState, newState) {
      if (!newState.doc.attrs.marginNumbers) return // Margin numbers aren't enabled
      if (oldState.doc.eq(newState.doc)) return // Doc hasn't changed

      const { doc, tr } = newState

      const { node, position } = findLastBlock(
        doc,
        (node) => hasContent(node) || !!node.attrs.marginNumber
      )

      if (node?.attrs.marginNumberRemoved) return

      if (hasContent(node) && !node?.attrs.marginNumber) {
        const lastNumber = findPrecedingMarginNumber(doc)
        if (!lastNumber) return

        return tr.setNodeAttribute(
          position,
          "marginNumber",
          // Convert the number to a string to ensure
          // margin numbers are always strings
          `${lastNumber + 1}`
        )
      }

      if (!hasContent(node) && node?.attrs.marginNumber) {
        return tr.setNodeAttribute(position, "marginNumber", null)
      }

      return
    },
  })
}

function findPrecedingMarginNumber(doc: Node) {
  const { node } = findLastBlock(doc, (node) => !!node.attrs.marginNumber)

  return node?.attrs.marginNumber
    ? parseNumber(node.attrs.marginNumber)
    : undefined
}

function hasContent(node?: Node) {
  if (!node) return false
  if (node.type.name !== "paragraph") return true
  return node.textContent.length > 0
}

function parseNumber(value: string | number) {
  try {
    return typeof value === "number" ? value : parseInt(value, 10)
  } catch (e) {
    sendErrorToSentry("failed to parse margin number", e)
    return undefined
  }
}
