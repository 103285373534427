import { Mark, Node, ResolvedPos, Schema } from "prosemirror-model"
import type { Mapping } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

import type { JSONContent, Range } from "@/types/utils"

/**
 * @description Check if the schema of the node includes a given attribute
 */
export function isAttributeAllowed({
  schema,
  node,
  attribute,
}: {
  schema: Schema
  node: Node
  attribute: string
}) {
  const attributeSpec = schema?.nodes?.[node.type.name]?.spec?.attrs
  return Object.keys(attributeSpec ?? {}).some((val) => val === attribute)
}

export function getEditorAttributes(view: EditorView) {
  return typeof view.props.attributes === "function"
    ? view.props.attributes(view.state)
    : view.props.attributes
}

export const getNodesFromJSONContent = (doc: JSONContent, nodeType: string) => {
  const nodes: JSONContent[] = []

  const traverse = (node: JSONContent) => {
    if (node?.type === nodeType) nodes.push(node)
    if (node.content && Array.isArray(node.content)) {
      for (let i = node.content.length - 1; i >= 0; i--) {
        const child = node.content[i]
        if (child) traverse(child)
      }
    }
  }

  traverse(doc)

  return nodes
}

const getMarkTypePredicate = (type: string) => (mark: Mark) =>
  mark.type.name === type
export function getMarkRange(doc: Node, markType: string, pos: Range | number) {
  const predicate = getMarkTypePredicate(markType)
  let start = typeof pos === "number" ? pos : pos.from
  let end = typeof pos === "number" ? pos : pos.to

  while (start > 0 && doc.nodeAt(start - 1)?.marks.some(predicate)) {
    start -= 1
  }

  while (end < doc.content.size && doc.nodeAt(end)?.marks.some(predicate)) {
    end += 1
  }

  return {
    from: start,
    to: end,
  }
}

export function isEditorDocEmpty(doc: Node) {
  if (!doc.content.size) return true
  if (doc.textContent || doc.childCount > 1) return false
  if (doc.childCount === 1 && doc.firstChild?.isAtom) return false
  if (
    doc.childCount === 1 &&
    doc.firstChild?.isTextblock &&
    doc.firstChild?.content?.size
  )
    return false

  return doc.childCount === 1 && doc.firstChild?.type.name === "paragraph"
}

export function findAncestor(
  $from: ResolvedPos,
  predicate: (node?: Node) => boolean
) {
  let depth = $from.depth

  while (depth >= 0) {
    const node = $from.node(depth)
    if (predicate(node)) {
      return { node, depth, pos: $from.before(depth) }
    } else {
      depth--
    }
  }

  return undefined
}

/**
 * Returns the DOMRect of a given range in the editor.
 * https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/helpers/posToDOMRect.ts
 */
export function posToDOMRect(
  view: EditorView,
  from: number,
  to: number
): DOMRect {
  const minPos = 0
  const maxPos = view.state.doc.content.size
  const resolvedFrom = Math.min(Math.max(from, minPos), maxPos)
  const resolvedEnd = Math.min(Math.max(to, minPos), maxPos)
  const start = view.coordsAtPos(resolvedFrom)
  const end = view.coordsAtPos(resolvedEnd, -1)
  const top = Math.min(start.top, end.top)
  const bottom = Math.max(start.bottom, end.bottom)
  const left = Math.min(start.left, end.left)
  const right = Math.max(start.right, end.right)
  const width = right - left
  const height = bottom - top
  const x = (left + right) / 2
  const y = top
  const data = {
    top,
    bottom,
    left,
    right,
    width,
    height,
    x,
    y,
  }

  return {
    ...data,
    toJSON: () => data,
  }
}

/*
 * Like textContent, but indexes in the resulting string
 * match the positions in the provided node.
 *
 * This allows us to perform operations on the document
 * as a string, and apply the resulting positions back to
 * the actual ProseMirror document.
 *
 * Example:
 *
 *  For the following document:
 *    doc
 *    |-> list
 *        |-> list item
 *            |-> paragraph
 *                |-> text: A reference § 123 BGB
 *            |-> list
 *                |-> list item
 *                    |-> paragraph
 *                        |-> text: Another reference §§ 124, 125 BGB
 *
 *  this function will produce the string:
 *
 *    ￼￼￼A reference § 123 BGB ￼￼￼￼Another reference §§ 124, 125 BGB￼￼￼￼￼
 *
 *  The first "§" character is at index 15 in the resulting string,
 *  and is also at position 15 in the ProseMirror document.
 */
export function positionMatchedTextContent(node: Node) {
  let textContent = ""
  node.forEach((child) => {
    if (child.isText) {
      textContent += child.text
      return
    }

    if (child.isLeaf) {
      textContent += "\ufffc"
      return
    }

    textContent += "\ufffc"
    textContent += positionMatchedTextContent(child)
    textContent += "\ufffc"
  })
  return textContent
}

function hasManualLink(schema: Schema, marks: readonly Mark[]) {
  for (const mark of marks) {
    if (mark.type === schema.marks["link"] && !mark.attrs["autoLinked"]) {
      return true
    }
  }
  return false
}

export function rangeHasManualLink(doc: Node, from: number, to: number) {
  let found = false
  if (to > from) {
    doc.nodesBetween(from, to, (node) => {
      if (hasManualLink(doc.type.schema, node.marks)) {
        found = true
      }
      return !found
    })
  }
  return found
}

export function maybeMap(mapping: Mapping, pos: number, assoc = 1): number {
  const result = mapping.mapResult(pos, assoc)
  return result.deleted ? pos : result.pos
}

/**
 * Finds the last root-level block node inside a document.
 * The predicate function can be used to filter the block nodes.
 */

export function findLastBlock(doc: Node, predicate?: (node: Node) => boolean) {
  let lastBlock = undefined
  let position = doc.content.size

  for (let i = doc.content.size; i >= 0; i--) {
    const node = doc.nodeAt(i)
    const $pos = doc.resolve(i)

    if (!node?.isBlock || $pos.depth !== 0) continue

    const isIncluded = !predicate || (predicate && predicate(node))
    if (!isIncluded) continue

    lastBlock = node
    position = i
    break
  }

  return { node: lastBlock, position }
}
